.vuiFlexItem {
  display: flex;
  flex-direction: column;
  flex-basis: 0%;
}

.vuiFlexItem--truncate {
  min-width: 40px;
}

@for $i from 0 through 10 {
  .vuiFlexItem--flexGrow#{$i} {
    flex-grow: $i;
  }
}

.vuiFlexItem--flexGrowNone {
  flex-basis: auto;
  flex-grow: 0;
}

@for $i from 0 through 10 {
  .vuiFlexItem--flexShrink#{$i} {
    flex-shrink: $i;
  }
}

.vuiFlexItem--flexShrinkNone {
  flex-basis: auto;
  flex-shrink: 0;
}
