.vuiListNumber {
  display: flex;
  flex-direction: column;
  width: $sizeM;
  height: $sizeM;
  padding: $sizeM;
  border-radius: $sizeL;
  background-color: $colorLightShade;
  color: $colorSubdued;
  font-size: $fontSizeMedium;
  font-weight: $fontWeightBold;
  line-height: 0;
  align-items: center;
}

.vuiListNumber-isComplete {
  background-color: $colorAccentLightShade;
  color: $colorAccent;
}
